<template>
  <div></div>
</template>

<script>

export default {
  computed: {
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  async mounted() {
    if (
      !this.$lodash.isEmpty(process.env.VUE_APP_TAWKTO_PROPERTY_ID) &&
      !this.$lodash.isEmpty(process.env.VUE_APP_TAWKTO_WIDGET_ID)
    ) {
      window.Tawk_API.endChat();
    }
    
    this.$store.commit("userStore/setOpenPaymentLinkSkipPrompt", false);
    this.$store.dispatch("authStore/logoutUser");
    this.$store.dispatch("businessStore/removeTestMode");
    window.location.href = "/login";
  },
};
</script>